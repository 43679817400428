import DeleteIcon from "@mui/icons-material/Delete";
import Instagram from "@mui/icons-material/Instagram";
import Email from "@mui/icons-material/Email";
import AmazonIcon from "../components/icons/AmazonIcon";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const Icons = {
    Delete: DeleteIcon,
    Instagram: Instagram,
    Email: Email,
    Amazon: AmazonIcon,
    Linkedin: LinkedInIcon,
    ArrowDown: ExpandMoreIcon,
    Home: HomeIcon,
    Menu: MenuIcon,
    Magic: AutoFixHighIcon,
};
export default Icons;
