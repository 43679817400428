import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LandingScreen from "../pages/LandingScreen";
import PCQRLibroScreen from "../pages/PCQRLibroScreen";
import PocketCardScreen from "../pages/Books/PocketCardScreen";
import ComposicionSMScreen from "../pages/Books/ComposicionSMScreen";
import MagiaScreen from "../pages/MagiaScreen";
import Navbar from "../components/Navbar";

const AppRouter = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<LandingScreen />} />
                <Route exact path="/magia" element={<MagiaScreen />} />
                <Route
                    exact
                    path="/magia/composicionsm"
                    element={<ComposicionSMScreen />}
                />
                <Route
                    exact
                    path="/magia/pocketcard"
                    element={<PocketCardScreen />}
                />
                <Route
                    exact
                    path="/magia/pocketcard/qrlibro"
                    element={<PCQRLibroScreen />}
                />
            </Routes>
        </Router>
    );
};

export default AppRouter;
