import React, { useState } from "react";

import portadaCSM from "../../imgs/PortadaComposicionShowsMentalismo.jpg";
import QueEsElMentalismo from "../../data/QueEsElMentalismo.pdf";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

import Book from "../../components/Book";
import Icons from "../../helpers/icons";

const PocketCardScreen = () => {
    const [openAccordion, setOpenAccordion] = useState("");

    const updateAccordion = (newAcc) =>
        setOpenAccordion(openAccordion === newAcc ? "" : newAcc);
    return (
        <Container
            className={"text-focus-in"}
            sx={{
                minHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
            }}
        >
            <Container>
                <br />
                <br />
                <Book
                    image={portadaCSM}
                    url="https://amzn.eu/d/dq3BYOi"
                    name="Composición de Shows de Mentalismo"
                    author={"Por Alejandro Loda"}
                    subline={"Próximamente a la venta"}
                />{" "}
                <br />
                <Accordion
                    sx={{ width: "100%" }}
                    expanded={openAccordion === "sobreElLibro"}
                    onClick={() => updateAccordion("sobreElLibro")}
                >
                    <AccordionSummary expandIcon={<Icons.ArrowDown />}>
                        <b>Sobre el libro</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        <b>Composición de Shows de Mentalismo</b> es la primera
                        obra de Alejandro Loda.
                        <br />
                        En ella se aborda su visión del mentalismo, y como este
                        debe girar en torno al <b>principio de coherencia</b>.
                        <br />
                        <br />
                        En sus páginas encontrarás <b>
                            qué es el mentalismo
                        </b>{" "}
                        para él, y por qué la coherencia en un show es tan
                        importante.
                        <br />
                        <br />
                        Alejandro propone una evolución del sistema de
                        clasificación de <i>Max Maven</i> (
                        <i>PRISM, The Blue Book of Mentalism, 1976</i>) acerca
                        de la naturaleza de los efectos mágicos mentales:
                        <ul
                            style={{
                                listStyleType: "disc",
                                paddingLeft: "20px",
                            }}
                        >
                            <li>Telepatía</li>
                            <li>Clarividencia</li>
                            <li>Precognición</li>
                            <li>Psicoquinesis</li>
                        </ul>
                        <br />
                        Agregando <b>4 nuevas categorías</b>, que buscan hacer
                        una <b>actualización</b> que se ajuste más al{" "}
                        <b>mentalismo del siglo XXI</b>:
                        <ul
                            style={{
                                listStyleType: "disc",
                                paddingLeft: "20px",
                            }}
                        >
                            <li>Condicionamiento</li>
                            <li>Suerte</li>
                            <li>Ciencia</li>
                            <li>Hipnosis</li>
                        </ul>
                        <br />
                        A través de sus páginas, encontrarás no solo la
                        definición de cada una de las 8 categoría, sino un juego
                        con el que ejemplificarlas. <br />
                        <br />
                        Con todo esto, así como un apartado de{" "}
                        <b>juegos extras</b> y teoría mágica, Alejandro
                        desarrolla un ejercicio paso a paso sobre como sería la
                        construcción de un show equilibrado, interesante y
                        eficaz a ojos del público.
                        <br />
                        <br />
                        Finalmente comparte la <b>herramienta</b> que usa para
                        poder llevar un <b>registro</b> de los juegos que
                        conoce, así como los eventos en los que participa.{" "}
                        <br /> Definitivamente un software gratuito, y sencillo
                        de usar, que querrás empezar a utilizar a diario si
                        quieres ser más organizado y productivo como mago.
                        <br />
                        <br />
                        En resumen, una obra que te abrirá la mente y nutrirá
                        tus espectáculos tanto de juegos como de estructura.
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{ width: "100%" }}
                    expanded={openAccordion === "indice"}
                    onClick={() => updateAccordion("indice")}
                >
                    <AccordionSummary expandIcon={<Icons.ArrowDown />}>
                        <b>Índice</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List disablePadding component="div">
                            {[
                                {
                                    title: "¿Qué es el mentalismo?",
                                    subchapters: [
                                        "¿Por qué es importante ser coherente?",
                                        "Como mejorar la coherencia del show",
                                    ],
                                },

                                {
                                    title: "Tipos de efectos de mentalismo",
                                    subchapters: [
                                        "Categorías por Max Maven",
                                        "Categorías por Alejandro Loda",
                                    ],
                                },
                                {
                                    title: "Juegos",
                                    subchapters: [
                                        "Ruleta Rusa Cara o Cruz",
                                        "Tossed Out Deck",
                                        "Centro Roto",
                                        "Free Will",
                                        "Péndulo de Nombres",
                                        "Piedras Negras y Blancas",
                                        "Vales por...",
                                        "Invisible Touch",
                                    ],
                                },
                                {
                                    title: "Componer un show",
                                    subchapters: [
                                        "Incluir juegos de magia",
                                        "Openers y Closers",
                                        "Como crear un juego de magia desde la técnica",
                                        "Como crear un juego de magia desde el efecto",
                                        "Estética en las predicciones",
                                        "Exceso de cartas/cartomagia",
                                        "Construir un show en base a uno o dos efectos",
                                        "Juegos más y de menos",
                                    ],
                                },
                                {
                                    title: "Notion",
                                    subchapters: [
                                        "Registrar juegos, técnicas e ideas",
                                        "Registrar shows",
                                        "Registrar libros",
                                    ],
                                },
                                {
                                    title: "Juegos Extras",
                                    subchapters: [
                                        "Lucky Invisible",
                                        "Invisible Glorpy",
                                        "Zapato y Sobre",
                                        "Intuición Iluminada",
                                        "Tarjetas de Forzaje",
                                    ],
                                },
                                {
                                    title: "Bibliografía recomendada",
                                    subchapters: ["En inglés", "En español"],
                                },
                            ].map((chapter, index) => (
                                <div key={index}>
                                    {/* Capítulo Principal */}
                                    <ListItem sx={{ p: 0 }}>
                                        <ListItemText primary={chapter.title} />
                                    </ListItem>
                                    {chapter.subchapters.length > 0 && (
                                        <List component="div" disablePadding>
                                            {chapter.subchapters.map(
                                                (subchapter, subIndex) => (
                                                    <ListItem
                                                        key={subIndex}
                                                        sx={{
                                                            pl: 4,
                                                            pt: 0,
                                                            pb: 0,
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={subchapter}
                                                        />
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    )}
                                    {/* {index < chapters.length - 1 && <Divider />} */}
                                </div>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{ width: "100%" }}
                    expanded={openAccordion === "queAporta"}
                    onClick={() => updateAccordion("queAporta")}
                >
                    <AccordionSummary expandIcon={<Icons.ArrowDown />}>
                        <span>
                            Extracto: <br />
                            <b>¿Qué es el mentalismo?</b>
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <embed
                            src={QueEsElMentalismo}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                        />
                    </AccordionDetails>
                </Accordion>
                <br />
            </Container>
            <br />
            <br />
            <br />
        </Container>
    );
};

export default PocketCardScreen;
